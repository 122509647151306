import axios, { AxiosInstance, AxiosResponse } from "axios";
import { merchantListKycResponse, 
  MerchantLocationlistkyc, 
  RiderKycList,
  RiderDetailsKyc, 
  allUserData,
  oldTlList,
  allUserReport ,
  merchantDataList,
  fieldVisit,
  shopCategory,
  allottedRider,
  hubDataList,
  CityList,
  UserMapped,
  clientReport
} from "../@Types/DRO/ApiResponses";

export class RiderKycApi {
  public static api = new RiderKycApi();
  private instance: AxiosInstance;
  private instanceurl: AxiosInstance;


  constructor() {
    this.instance = axios.create({
      baseURL: "https://beta.bcykal.com/mobycy/clientDash/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
    this.instanceurl = axios.create({
      baseURL: "https://dash.bcykal.com/",
     
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  

  riderDetailTrackingList(
    searchVal?: string | null,
    page?: number | null,
    rowsPerPage?: number | null,
    
  ): Promise<RiderKycList> {
    let apiUrl = "v1/create/client/request/list";
    if (searchVal) {
      apiUrl += `?search=${searchVal}`;
    }
    return this.instance
      .get<RiderKycList>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: rowsPerPage,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  allottedRider(
    search?: string | null,
    page?: number | null,
    pageSize?: number | null,
    
  ): Promise<allottedRider> {
    let apiUrl = "get/riderReport";
    if (search) {
      apiUrl += `?search=${search}`;
    }
    return this.instance
      .get<allottedRider>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  clientReportList(
    page?: number | null,
    pageSize?: number | null,
    filterMerchant?: number | null,
    
  ): Promise<clientReport> {
    let apiUrl = "get/data/onboardingClients";
    if (filterMerchant) {
      apiUrl += `?id=${filterMerchant}`;
    }
    
    return this.instance
      .get<clientReport>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  alluserData(
    search?: string | null,
    page?: number | null,
    pageSize?: number | null,
    
  ): Promise<allUserData> {
    let apiUrl = "get/userReport";
    if (search) {
      apiUrl += `?search=${search}`;
    }
    return this.instance
      .get<allUserData>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  alluserReport(
    search?: string | null,
    page?: number | null,
    pageSize?: number | null,
    userid?: number | null,
    
  ): Promise<allUserReport> {
    let apiUrl = "get/userAttendance";
    apiUrl +=`?userId=${userid}`;

    if (search) {
      apiUrl += `?search=${search}`;
    }
    return this.instance
      .get<allUserReport>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  fieldVisitData(
    search?: string | null,
    page?: number | null,
    pageSize?: number | null,
    userid?: number | null,
    
  ): Promise<fieldVisit> {
    let apiUrl = "get/fieldVisit";
    apiUrl +=`?userId=${userid}`;

    if (search) {
      apiUrl += `?search=${search}`;
    }
    return this.instance
      .get<fieldVisit>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  merchantDataList(
  ): Promise<merchantDataList> {
    let apiUrl = "get/data/merchant";
    return this.instance
      .get<merchantDataList>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
       
      })
      .then((e) => {
        return e.data;
      });
  }
  AllCityList(
  ): Promise<CityList> {
    let apiUrl = "get/data/cityList";
    return this.instance
      .get<CityList>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
       
      })
      .then((e) => {
        return e.data;
      });
  }
  UserMapped(
    selectedRole?: number | null,
  ): Promise<UserMapped> {
    let apiUrl = "get/data/mappingHead";
    apiUrl +=`?change=${selectedRole}`;
    return this.instance
      .get<UserMapped>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
       
      })
      .then((e) => {
        return e.data;
      });
  }
  hubDataList(
  ): Promise<hubDataList> {
    let apiUrl = "get/riderHubDetails";
    return this.instance
      .get<hubDataList>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
       
      })
      .then((e) => {
        return e.data;
      });
  }
  shopCategory(
  ): Promise<shopCategory> {
    let apiUrl = "get/merchantCategory";
    return this.instance
      .get<shopCategory>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
       
      })
      .then((e) => {
        return e.data;
      });
  }
 
  oldTlList(
    userId?: number | null,
  ): Promise<oldTlList> {
    let apiUrl = "get/data/changeExecutive";
    apiUrl +=`?change=${userId}`;
    return this.instance
      .get<oldTlList>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
       
      })
      .then((e) => {
        return e.data;
      });
  }
  merchantHyperTrackList(
    userId?: number | null,
  ): Promise<merchantListKycResponse> {
    let apiUrl = "v1/merchant/list";
    apiUrl +=`?userId=${userId}`;
    return this.instance
      .get<merchantListKycResponse>(apiUrl, {
       
      })
      .then((e) => {
        return e.data;
      });
  }
  merchantStoreListHyperTrack(
    riderDetailsMerchantId?: number | null,
    
  ): Promise<MerchantLocationlistkyc> {
    let apiUrl = "v1/merchant/store/list";
    if (riderDetailsMerchantId) {
      apiUrl +=`?merchantId=${riderDetailsMerchantId}`;
    }
    return this.instance
      .get<MerchantLocationlistkyc>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  riderDetailsKyc(
    id?: number | null,
    
  ): Promise<RiderDetailsKyc> {
    let apiUrl = "v1/userDetail/userId";
    if (id) {
      apiUrl +=`?id=${id}`;
    }
    return this.instance
      .get<RiderDetailsKyc>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

}
